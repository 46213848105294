<template>
  <shop
    :filters="filters"
    :page="page"
    :paged-path-prefix="pagedPathPrefix"
  />
</template>

<script>
  import Shop from './Shop.vue'

  export default {
    name: 'ShopCategory',
    components: {
      Shop,
    },
    props: {
      categoryId: [Number, String],
      page: {
        type: [Number, String],
        default: 1,
      },
    },
    computed: {
      pagedPathPrefix () {
        return '/shop/category/' + this.categoryId + '/'
      },
      categoryTitle () {
        return this.categories.find(c => parseInt(c.id) === parseInt(this.categoryId))?.data?.title ?? ''
      },
      filters () {
        return {
          categories: {
            [this.categoryId]: true,
          },
          sort: 'publish_at_desc',
        }
      },
    },
    mounted () {
      return this.$store.dispatch('repos/fetchProductCategories').then(categories => {
        function flatten (arr) {
          return arr?.reduce((f, c) => {
            f.push(c)
            f.push(...flatten(c.children))
            return f
          }, []) ?? []
        }
        this.categories = flatten(categories)
        window.document.title = this.$t('pagesTitles.category', [
          this.categoryTitle,
        ])
      }).finally(() => {
        this.$store.dispatch('analytics/trackProductCategoryView', this.categoryTitle)
      })
    },
  }
</script>
